<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','编辑人员'):$l('company.add','新增人员')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <!--      <el-form-item :label="$l('project.name','客户公司')" prop="customerCompanyId" :rules="$rule.notNull">-->
      <!--        <vm-select-input :value="model.customerCompanyName" @select="$refs.companySelect.open()"></vm-select-input>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="楼盘名称" prop="realEstateId" :rules="$rule.notNull">-->
      <!--        <vm-select-input :value="model.realEstateName" @select="$refs.customerSelect.open({customerCompanyId})"></vm-select-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="楼盘名称" prop="realEstates">
        <el-select v-model="model.realEstates" multiple placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select v-model="model.gender" :placeholder="$l('common.enter','请输入')">
          <el-option
            v-for="item in gender"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" :rules="$rule.notNull">
        <el-input v-model="model.phone" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="职务" prop="post">
        <el-input v-model="model.post" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <!--      <el-form-item label="用户角色" prop="roleIds">-->
      <!--        <el-select v-model="model.roles" multiple placeholder="请选择">-->
      <!--          <el-option-->
      <!--            v-for="item in options"-->
      <!--            :key="item.id"-->
      <!--            :label="item.name"-->
      <!--            :value="item.id"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="备注" prop="remark">
        <el-input v-model="model.remark" type="textarea" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <company-select ref="companySelect" @select="companySelect"></company-select>
    <customer-select ref="customerSelect" @select="selectCustomer"></customer-select>
  </el-dialog>
</template>
<script>
  import CompanySelect from "@/views/elevatorUsingCompany/CompanySelect";
  import CustomerSelect from "@/views/elevatorUsingCustomer/RealEstateSelectForCustomer";

  export default {
    components: {CompanySelect,CustomerSelect},
    props:{
      customerCompanyId:Number,
    },
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          shortName: "",
          type: "",
          creditCode: "",
          remark: "",
          post: "",
          roles: [],
        },
        options: [],
        gender:[{
                  value:"male",
                  label:"男",
                },
                {
                  value:"female",
                  label:"女",
                }],
      };
    },
    mounted() {
      console.log(this.customerCompanyId);
      this.model.customerCompanyId = this.customerCompanyId;

    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
        this.getRoles();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`customer-relationship/customer/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            this.model.roles= data.roles.map(item=>item.id);
            console.log(this.model,126);
            // if(data.customerCompanyId) {
            //   this.getRealstate(data.customerCompanyId);
            // }
            this.model.realEstates= data.realEstates.map(item=>item.id);
          });
        }
      },
      getRoles() {
        const userInfo = JSON.parse(localStorage.getItem("hold_user_info"));
        if (userInfo.customer?.customerCompanyId) {
          this.$http.get("real-estate/list",{customerCompanyId:userInfo.customer.customerCompanyId}).then(data => {
            this.options = data;
          });
        }

      },
      companySelect(row) {
        this.$set(this.model,"customerCompanyId", row.id);
        this.$set(this.model,"customerCompanyName", row.name);
      },
      selectCustomer(row) {
        this.$set(this.model,"realEstateId",row.id);
        this.$set(this.model,"realEstateName",row.name);
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.model.customerCompanyId = this.customerCompanyId;

            const realEstates = this.model.realEstates.map(item => ({id: item}));
            const roles = this.model.roles.map(item => ({id: item}));

            this.$http
              .save("customer-relationship/customer", {...this.model,roles,realEstates})
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
